'use strict';
angular.module('kljDigitalLibraryApp')
    .factory('common', ["$q", "$http", "$rootScope",
        function($q, $http, $rootScope) {
            var factory = {};
            factory.callApi = function(method, url, params, headers, body) {
                if ($rootScope.offlineApp == true) {
                    let defered = $q.defer();

                    function createRequestObject() {
                        var req = {};
                        req.method = method;
                        req.url = url;
                        req.query = params;
                        req.headers = headers;
                        req.body = body;

                        return req;
                    }

                    function createResponse(promise) {

                        var res = new Object()
                        res.defered = promise;
                        res.status = function(status) {


                            var obj = {}
                            obj.status = status;
                            obj.send = function(data) {

                                if (obj.status === 200) {
                                    var result = {}
                                    result.data = data;
                                    res.defered.resolve(result);
                                } else
                                    res.defered.reject(data);
                            }
                            return obj;
                        }
                        return res;
                    }
                    // var defered = $q.defer();		
                    var req = createRequestObject();
                    var res = createResponse(defered);
                    // console.log(req, "============");
                    offlineHandler(req, res)
                    return defered.promise;
                    // return deffered.promise;
                } else {
                    let deffered = $q.defer();
                    // Simple GET request example:
                    $http({
                        method: method,
                        url: url,
                        params: params,
                        headers: headers,
                        data: body
                    }).then(function successCallback(response) {
                        
                        // this callback will be called asynchronously
                        // when the response is available
                        deffered.resolve(response);
                    }, function errorCallback(error) {
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                        deffered.reject(error);
                    });
                    return deffered.promise;
                }
            }


            return factory;

        }
    ]);